/** @jsxRuntime classic */
/** @jsx jsx */
// @ts-check
import { Container, Text } from "@bottlebooks/gatsby-theme-base";
import { Global } from "@emotion/core";
import { graphql } from "gatsby";
import { jsx } from "theme-ui";
import EmbedLayout from "../../components/EmbedLayout";

export default function BrellaIndexPage({ data }) {
  const tallViewport = "@media screen and (min-height: 400px)";
  return (
    <EmbedLayout sx={{ backgroundColor: "light" }}>
      <Global styles={{ body: { [tallViewport]: { overflow: "hidden" } } }} />
      <Container
        sx={{ paddingY: 3, maxWidth: [null, null, "100rem"], height: "100%" }}
      >
        <Text>Brella LandingPage</Text>
      </Container>
    </EmbedLayout>
  );
}

export const pageQuery = graphql`
  query BrellaIndexPage($collectionId: ID!) {
    bottlebooks {
      event(eventId: $collectionId) {
        mainImage {
          fluid(
            maxWidth: 1280
            maxHeight: 360
            crop: FILL
            gravity: FACES_AUTO
          ) {
            src
          }
        }
      }
    }
  }
`;
